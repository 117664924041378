import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { FaArrowRightLong } from 'react-icons/fa6';
import CityRoutesList from '../SEO/CityRoutesList';
import { WebsiteName, WebsiteUrl, PhoneNumber } from '../config/config'

// Define the list of taxi routes with different city data

const FamousTaxi = () => {
    useEffect(() => {
        window.scrollTo(10, 10);
    }, []);

    return (
        <div className="container">
            <h2 className="heading">Our Famous Taxi Routes</h2>
            <h4 className="text-center">Lucky One Way Taxi can be often seen on these following routes</h4>

            <div className="row">
                {CityRoutesList.map((cityData, index) => (
                    <div className="col-12 col-xl-4 col-md-6" key={index}>
                        <div className="card">

                            <Link to={cityData.city} className="img-card">
                                <img src={cityData.img} alt="Taxi" />
                            </Link>
                            <div className="card-content">
                                <Link to={cityData.city}>   <h4 className="card-title CityHeading">
                                    From {cityData.city}
                                </h4></Link>
                                <ul>
                                    {cityData.routes.map((route, index) => (
                                        <div key={index}>{cityData.city.toLowerCase() === route.toLowerCase() ? '' :
                                            <li className="FamousTextList">
                                                <Link to={`/${cityData.city.toLowerCase()}/${cityData.city.toLowerCase()}-to-${route.toLowerCase()}`}>
                                                    <p className='CityHeading'>   {cityData.city} to {route} one way drop taxi </p>
                                                </Link>
                                            </li>}
                                        </div>
                                    ))}
                                </ul>
                            </div>
                            <div className='d-lg-flex d-flex flex-wrap gap-3 flex-lg-nowrap mb-3 px-2'>
                                <button
                                    variant="dark"
                                    className="d-flex align-items-center justify-content-center w-100 py-2 rounded-md pdt-btn"
                                >
                                    <a href='#Form'>
                                        <span className="me-2">Book Now</span>
                                        <FaArrowRightLong size={17} />
                                    </a>
                                </button>

                                <button
                                    variant="dark"
                                    className="d-flex align-items-center justify-content-center w-100 py-2 rounded-md pdt-btn"
                                >
                                    <a href={`tel:${PhoneNumber}`}>
                                        <span className="me-2">CALL NOW</span>
                                        <FaArrowRightLong size={17} />
                                    </a>
                                </button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {CityRoutesList.map((routeData, index) => (
                <div key={index}>
                </div>
            ))}
        </div>
    );
};

export default FamousTaxi;
