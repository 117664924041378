const telegramid = '6932943855:AAFR8HjIe96gyrBm-BiWyQzU0BvEt1mnmeo'
const telegramChatId = '1113684027'
const TelegramApi = '7534886411:AAHCuuSZ_opG9AtMdTksoykDJWjI-dZghJk';
const ChatId = '7836325482';
// const telegramid = '7775422884:AAFEDubjNvTf3mU_FMs5UFAMoIqj01HSmYM'
// const telegramChatId = '6130998735'
// const TelegramApi = '7775422884:AAFEDubjNvTf3mU_FMs5UFAMoIqj01HSmYM';
// const ChatId = '6130998735';

const WhatsappCode = '+919600969362'
const PhoneNumber = '+91 9600969362'
const WebsiteUrl = 'http://www.luckyonewaytaxi.in/'
const WebsiteName = 'Lucky One Way Taxi'



export { telegramid, telegramChatId, TelegramApi, ChatId, WhatsappCode, WebsiteUrl, PhoneNumber, WebsiteName }

