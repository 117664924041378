import React from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import MainSEOList from './MainSEOList';
import { useParams } from 'react-router-dom';

const Testimonials = () => {
	const { City, Routes } = useParams();  // Get the cityName from the URL

	const seoData = MainSEOList.find(item => item.city.toLowerCase() === City.toLowerCase());

	return (
		<div>
			{seoData.CustomerTestimonialsDes && seoData.CustomerTestimonialsDes.length > 0 && (
				<div className='bgc-primary pt-4 pd-3 mb-3'>
					<div>

						<h2>Testimonials</h2>

					</div>

					{/* Customer Testimonials Carousel */}

					<Carousel className='w-75'>
						{seoData.CustomerTestimonialsDes.map((testimonial, index) => (
							<Carousel.Item key={index}>
								<p className='text-white mt-3'>{seoData.CustomerTestimonials}</p>
								<p className="SEOSubtitle">{testimonial.testimonial}</p>
							</Carousel.Item>
						))}
					</Carousel>

				</div>
			)}
		</div>
	);
};

export default Testimonials;
