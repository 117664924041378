import React from 'react'
import bannerImg from "../img/cars/bannerImg.png"
import { IoCall } from "react-icons/io5";
import Form from "../Form/Form"
import { WhatsappCode, PhoneNumber } from '../config/config';


const Banner = () => {
    return (
        <div style={{ backgroundColor: '#CECABF' }}>
            <div className='container pt-lg-5 pb-5' id='Home' >
                <div className='row'>
                    <div className='col-12 col-lg-7 bannerContent1'>
                        <div className='py-3 px-2 homecontentform'>
                            <img className='bannerImg' src={bannerImg} />
                            <p className='parastyle'>Book Your Ride Today!</p>
                            <p>Experience the ease of booking with Lucky One Way Taxi.
                                <br />Call us or book online for an affordable and stress-free journey. </p>
                            <p>Lucky One Way Taxi – Travel Smart, Pay Less!</p>

                            <h4 className='parastyle'>Booking Call - 24/7</h4>
                            <h3>     <button className='MenuButton '> <IoCall /> <a href={`tel:${PhoneNumber}`} className='Callstyle'>{PhoneNumber}</a> </button>
                            </h3>
                        </div>
                    </div>

                    <div className='col-12 col-lg-5 formMain formBo' >
                        <Form />
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Banner