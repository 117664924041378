import React, { useEffect,  useState } from 'react'
import { Link, Route, useParams } from 'react-router-dom';
import CityRoutesList from './CityRoutesList'
import { FaCar } from "react-icons/fa";
import Form from "../Form/Form";
import LocationComponent from './LocationComponent'
import { GoogleMap, Marker, LoadScript, DirectionsRenderer, useJsApiLoader } from '@react-google-maps/api';
import { cityCoordinates } from './LocationComponent';
import CarsChargeTable from "./CarsChargeTable";
import SubSEOList from './SubSEOList'
import Banner from '../Form/Banner';
import imageInn from '../img/AboutUsImg.png'
import hoverImg from './img/Bangalor.jpg'



const SubSEO = () => {
    const { City, Routes } = useParams();
    const [directions, setDirections] = useState(null);
    const [startCity, endCity] = Routes.toLowerCase() ? Routes.split('-to-') : ['', ''];
    const [markers, setMarkers] = useState([]);

    const cityData = CityRoutesList.find(city => city.city.toLowerCase() === startCity.toLowerCase());
    console.log(cityData);

    

    // Filter SubSEOList based on the startCity (and possibly endCity, if you want specific content)
    // const CityData = SubSEOList.find(cityData => cityData.city === startCity);

    // const CityData = SubSEOList.find(cityData =>
    //     cityData.city && cityData.city === startCity && cityData.city === endCity);


    const CityData = SubSEOList.find(cityData =>
        cityData.city === startCity &&
        cityData.routes === endCity
    );

    console.log(startCity, endCity);

    // Load Google Maps API
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: 'AIzaSyBj7X_vm02csFhFln1d0YPu72VBkccy8b8',
        libraries: ['directions'],
    });

    useEffect(() => {
        window.scrollTo(10, 10);

        // Reset directions and markers whenever route changes
        setDirections(null);
        setMarkers([]);

        if (startCity && endCity && window.google) {
            const directionsService = new window.google.maps.DirectionsService();
            const directionsRenderer = new window.google.maps.DirectionsRenderer();

            const startCoords = cityCoordinates[startCity];
            const endCoords = cityCoordinates[endCity];

            if (startCoords && endCoords) {
                directionsService.route(
                    {
                        origin: startCoords,
                        destination: endCoords,
                        travelMode: window.google.maps.TravelMode.DRIVING,
                    },
                    (result, status) => {
                        if (status === window.google.maps.DirectionsStatus.OK) {
                            setDirections(result);
                            setMarkers([
                                { position: startCoords },
                                { position: endCoords },
                            ]);
                        } else {
                            console.error('Directions request failed due to ' + status);
                        }
                    }
                );
            }
        }

    }, [startCity, endCity]);

    if (!isLoaded) {
        return <div> <Banner />
            <h2> SEO data not found for {City} to {Route}</h2>
        </div>;
    }


    

    function capitalizeWords(string) {
        const validString = string || ''; // Ensure it is always a string
        return validString
            .replace(/-/g, ' ') // Replace all hyphens with spaces
            .split(' ') // Split by space
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
            .join(' '); // Join words back with spaces
    }

    return (
        <>
            <div className='container SEOMultiPage mt-2'>
                <h1 className='fw-bolder text-center'> {capitalizeWords(Routes)} one-way drop taxi                </h1>


                <div className='row'>
                    {/* Form Section */}
                    <div className='col-12 col-lg-6 mb-3'>
                        <Form />
                    </div>
                    {/* Google Map Section */}
                    <div className="col-12 col-lg-6 mb-3">
                        <GoogleMap
                            mapContainerClassName="mapContainerStyle"
                            mapContainerStyle={{ width: '100%', height: '692px' }}
                            center={cityCoordinates[startCity] || { lat: 13.0827, lng: 80.2707 }}
                            zoom={6}
                        >
                            {markers.map((marker, index) => (
                                <Marker key={index} position={marker.position} label={marker.label} />
                            ))}
                            {directions && <DirectionsRenderer directions={directions} />}
                        </GoogleMap>
                    </div>
                </div>

                {/* Dynamic Content Based on Selected Route */}
                {CityData ? (
                    <>

                        <p className='SEOtitle'>{CityData.title}</p>
                        <p>{CityData.description}</p>

                        {CityData.WhyChooseHeading && CityData.WhyChooseDesc && CityData.WhyChooseDesc.length > 0 && (
                            <>
                                <h3 className='SEOSubtitle mt-3'>{CityData.WhyChooseHeading}</h3>
                                <ol>
                                    {CityData.WhyChooseDesc.map((benefit, index) => (
                                        <li key={index}>
                                            <h5 className='SEOSubtitle'>{benefit.title}</h5>
                                            <p className='pt-3'>{benefit.description}</p>
                                        </li>
                                    ))}
                                </ol>
                            </>
                        )}


                        <div className="container pt-2 pb-2 mb-2 bgc-primary">
                            <h2 > <span className='textCol-secondary'>{capitalizeWords(Routes)}  </span> Taxi Fare Details</h2>
                            <table className="table SEOTable table-bordered">
                                <thead>
                                    <tr>
                                        <th>Vehicle Type</th>
                                        <th>Rate/KM</th>
                                        <th>Trip Fare</th>
                                        <th>Additional Charge</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {CityData.TaxiFareDetails.map((fare, idx) => (
                                        <tr key={idx}>
                                            <td className="p-[5px]">{fare.CarType}</td>
                                            <td className="p-[5px]">{fare.RatePerKM}</td>
                                            <td className="p-[5px]">{fare.TripFare}</td>
                                            <td className="p-[5px]">{fare.AdditionalCharge}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        {CityData.BenefitsHeading && CityData.BenefitsDesc && CityData.BenefitsDesc.length > 0 && (
                            <>
                                <h3 className='SEOSubtitle mt-3'>{CityData.BenefitsHeading}</h3>
                                <ol>
                                    {CityData.BenefitsDesc.map((benefit, index) => (
                                        <li key={index}>
                                            <h5 className=''>{benefit.title}</h5>
                                            <p className='pt-3'>{benefit.description}</p>
                                        </li>
                                    ))}
                                </ol>
                            </>
                        )}

                        {CityData.FeaturesHeading && CityData.FeaturesDesc && CityData.FeaturesDesc.length > 0 && (
                            <>
                                <h3 className='SEOSubtitle mt-3'>{CityData.FeaturesHeading}</h3>
                                <ol>

                                    {CityData.FeaturesDesc.map((benefits, index) => (
                                        <li key={index}>
                                            <h5 className=''>{benefits.title}</h5>
                                            <p className='pt-3'>{benefits.description}</p>
                                        </li>
                                    ))}
                                </ol>
                            </>
                        )}

                        <p className='SEOtitle'>{CityData.BookNowHeading}</p>
                        <p className=''>{CityData.BookNowdesc1}</p>
                        <p>{CityData.BookNowdesc2}</p>


                        <div className="row">
                            {cityData.routes.map((route, index) => (
                                cityData.city === route ? null : (
                                    <div className="col-12 col-sm-6 col-lg-4" key={index}>
                                        <div className="card SEOcard mb-3 text-center">
                                            <div className="card-body">
                                                <Link to={`/${cityData.city}/${cityData.city}-to-${route}`} className="text-decoration-none">
                                                    <h5 className='CityHeading'>{cityData.city} to {route} one way drop taxi</h5>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                )
                            ))}
                        </div>
                    </>
                ) : (
                    <div className="container px-3 py-4">
                        <p>
                            <h2> SEO data not found for {Routes} </h2>
                        </p>
                    </div>
                )}

                {/* <div className="imageBox">
                    <div className="imageInn">
                        <img src={imageInn} alt="Default Image"/>
                    </div>
                    <div className="hoverImg">
                        <img src={hoverImg} alt="Profile Image"/>
                    </div>
                </div> */}
            </div>
        </>
    );
};

export default SubSEO