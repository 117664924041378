import React from 'react'
import logo from "../img/luckyonewaytaxi_logo.png"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import what_img from "../img/whatsapp.png";
import { FaWhatsapp } from "react-icons/fa";
import { IoMdCall } from "react-icons/io";
import { WhatsappCode, PhoneNumber } from '../config/config';
import { FaRegCopyright } from "react-icons/fa";
import { WebsiteUrl, WebsiteName } from '../config/config'
import CityRoutesList from '../SEO/CityRoutesList';
import { Link, useParams } from 'react-router-dom';
import { useEffect } from 'react';





const Footer = () => {
    const { City, Routes } = useParams();  // Get the cityName from the URL

    // Function to scroll to the top when called
    const handleScrollToTop = () => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth' // Smooth scroll effect
        });
      };  useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page when the component is mounted
    }, [City]);

    return (
        <div>

            <div className='container mt-5' id='Contact'>
                <div className='row'>

                    <div className='col-12 col-lg-3'>
                        <a className='navbar-brand' href='/'>
                            <img src={logo} />
                        </a>
                        <p className="text-justify">We are providing lucky one way taxi, Outstation Taxi, and Airport Taxi. Hire lucky one way taxi with cheapest price right at your place. Always Available 24/7.</p>
                    </div>
                    <div className='col-12 col-lg-3 text-justify'>
                        <ul><h3>Quick Links</h3>
                            <li className='footerMenu'><a href='/' aria-current="page">Home</a></li>
                            <li className='footerMenu'><a href='#About'>About</a></li>
                            <li className='footerMenu'><a href='#Tariff'>Tariff</a></li>
                            <li className='footerMenu'><a href='#Contact'>Contact</a></li>
                        </ul>

                    </div>

                    <div className='col-12 col-lg-3 text-justify text-black'>
                        <h3>Official Info</h3>
                        <h5>Location:</h5>
                        <p>Tirunelveli, Tamil Nadu, India.</p>
                        <h5>Email: </h5>
                        <p>luckyonewaytaxi@gmail.com</p>
                        <h5>Phone:</h5>
                        <p><a href={`tel:${PhoneNumber}`} className='text-dark'>        {PhoneNumber}   </a> </p>
                    </div>

                    <div className='col-12 col-lg-3 '>
                        <div className='row'>
                            <h3>Drop Taxi Service</h3>

                            {CityRoutesList.map((cityData, index) => (
                                <div className='col-5' key={index}>
                                    <Link to={`/${cityData.city.toLowerCase()}`}>
                                        <h5 className='CityHeading text-black footerMenuCity' onClick={handleScrollToTop} >{cityData.city}</h5>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>

                </div>

            </div>

            <div className='bgc-primary text-center text-white p-3 d-flex align-item-center justify-content-center'>Copyright &#169;

                2024 . All Rights Reserved. Powered By  <a className='text-black fw-bold  ps-2' href={WebsiteUrl} >  {WebsiteName}</a></div>

            <div className='WhatsappNumber d-lg-block '>
                <div class="whatsapp-btn">
                    <div class="whatsappIcon-btn">
                        <a href={`https://wa.me/${WhatsappCode}?text=`} target="_blank" rel="noopener noreferrer">   <FaWhatsapp /></a>
                    </div>
                </div>
            </div><div className='PhoneNumber d-lg-block '>
                <div class="Phone-btn">
                    <div class="PhoneIcon-btn">
                        <a href={`tel:${PhoneNumber}`}><IoMdCall /></a>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Footer