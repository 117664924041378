import './App.css';
import './Our-famous-taxi-routes/SEOStyle.css'
// index.js or App.js
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Home from './Home';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MenuBar from './Pages/MenuBar';
import Footer from './Pages/Footer';
import Banner from './Form/Banner';
import SEOMainPage from './Our-famous-taxi-routes/SEOMultipleCity/SEOMainPage';
// import Drop_Taxi_Cities from './Our-famous-taxi-routes/Drop_Taxi_Cities';
import Drop_Taxi_Cities from './SEO/Drop_Taxi_Cities';

import CityDetails from './Our-famous-taxi-routes/CityDetails';
import IndivCity from './Our-famous-taxi-routes/IndivCity';
import SEOMultiPageDesgin from './Our-famous-taxi-routes/SEOMultiPageDesgin'
import MainSEO from "./SEO/MainSEO";
import SubSEO from "./SEO/SubSEO";
function App() {
  return (
    <BrowserRouter>
      <MenuBar />

      <Routes>
        <Route path="/" element={<Home />}>


        </Route>
        <Route path="/drop-taxi-cities" element={<Drop_Taxi_Cities />} />
        {/* <Route path="/:cityName" element={<IndivCity />} /> */}
        {/* <Route path="/:cityName/:routeName" element={<SEOMultiPageDesgin />} /> */}
        <Route path="/:City" element={<MainSEO />}></Route>
        <Route path="/:City/:Routes" element={<SubSEO />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
