import React, { useState, useEffect } from 'react';
import { GoogleMap, Marker, LoadScript, DirectionsRenderer, useJsApiLoader } from '@react-google-maps/api';
import { useParams, useLocation } from 'react-router-dom';

// City coordinates mapping
export const cityCoordinates = {
  madurai: { lat: 9.9395, lng: 78.1218 },
  chennai: { lat: 13.0827, lng: 80.2707 },
  trichy: { lat: 10.7905, lng: 78.7047 },
  coimbatore: { lat: 11.0168, lng: 76.9558 },
  salem: { lat: 11.6643, lng: 78.1460 },
  thiruvannamalai: { lat: 12.2192, lng: 79.0740 },
  thanjavur: { lat: 10.7870, lng: 79.1508 },
  bangalore: { lat: 12.9716, lng: 77.5946 },
  tuticorin: { lat: 8.7752, lng: 78.1349 },
  tirunelveli: { lat: 8.7277, lng: 77.7137 },
  kodaikanal: { lat: 10.2377, lng: 77.4899 },
  pudukottai: { lat: 10.3870, lng: 78.5304 },
  ooty: { lat: 11.4060, lng: 76.6963 },
  kanniyakumari: { lat: 8.0883, lng: 77.5000 },
  rameshwaram: { lat: 9.2884, lng: 79.3124 },
  thiruchendur: { lat: 8.4702, lng: 77.7656 },
  vellore: { lat: 12.9165, lng: 79.1320 },
  pondicherry: { lat: 11.9416, lng: 79.8083 },
  karur: { lat: 10.9649, lng: 78.0806 },
  namakkal: { lat: 11.2126, lng: 78.1495 },
  erode: { lat: 11.3411, lng: 77.7117 },
  ramanad: { lat: 9.5906, lng: 78.8395 },
};



const LocationComponent = () => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'YOUR_GOOGLE_MAPS_API_KEY',  // Replace with your actual Google Maps API key
    libraries: ['directions'],
  });

  const [directions, setDirections] = useState(null);
  const [markers, setMarkers] = useState([]);

  const { route } = useParams(); // Retrieve route params from URL
  const [startCity, endCity] = route ? route.split('-to-') : []; // Extract start and end cities from URL

  useEffect(() => {
    // Clear the previous markers and directions
    setMarkers([]);
    setDirections(null);

    if (startCity && endCity && window.google) {
      const directionsService = new window.google.maps.DirectionsService();

      const startCoords = cityCoordinates[startCity];
      const endCoords = cityCoordinates[endCity];

      if (startCoords && endCoords) {
        // Request directions from the API
        directionsService.route(
          {
            origin: startCoords,
            destination: endCoords,
            travelMode: "DRIVING",
          },
          (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
              setDirections(result);  // Set directions if the request is successful
              setMarkers([
                { position: startCoords, label: "Start" }, // Set start city marker
                { position: endCoords, label: "End" },   // Set end city marker
              ]);
            } else {
              console.error('Directions request failed due to ' + status);
            }
          }
        );
      }
    }
  }, [startCity, endCity]); // Re-run the effect when the route changes

  if (!isLoaded) {
    return <div>Loading Map...</div>;
  }

  return (
    <div>
      <h2>Route: {startCity} to {endCity}</h2>
      <div style={{ width: '100%', height: '600px' }}>
        <GoogleMap
          zoom={6}  // Adjust zoom level to cover the distance between cities
          center={cityCoordinates[startCity] || { lat: 13.0827, lng: 80.2707 }} // Center map on origin city
        >
          {/* Render the start city marker */}
          {startCity && cityCoordinates[startCity] && (
            <Marker position={cityCoordinates[startCity]} label="Start" />
          )}

          {/* Render the destination city marker */}
          {endCity && cityCoordinates[endCity] && (
            <Marker position={cityCoordinates[endCity]} label="End" />
          )}

          {/* Render the route directions if available */}
          {directions && (
            <DirectionsRenderer directions={directions} />
          )}
        </GoogleMap>
      </div>
    </div>
  );
};


export default LocationComponent;

