import React from 'react'
import { FaStar } from "react-icons/fa6";
import AboutUsImg from "../img/cars/AboutImg1.png"
import { FaArrowRightLong } from 'react-icons/fa6';
import { WhatsappCode, PhoneNumber } from '../config/config';


const About = () => {
    return (
        <div className="container" id='About' >
            <h2 className='mt-4'>ABOUT US            </h2>
            <div className='dividerMain'><div class="divider"><FaStar className='iconStyle' /></div></div>
            <div className="container mt-3">
                <div className="row align-items-center">
                    <div className="col-12 col-lg-6 text-justify">
                        <h3 className='font-weight-bold'>Welcome to Lucky One Way Taxi</h3>
                        <p>Your Trusted Partner for Hassle-Free Travel Across Tamil Nadu, Pondicherry, and Bangalore</p>
                        <h3 className='font-weight-bold'>At Lucky One Way Taxi, </h3>
                        <p>we specialize in providing reliable and affordable one-way taxi services. Whether you're traveling for business, leisure, or family needs, we ensure a smooth and comfortable ride to your destination.</p>

                        <div className='d-lg-flex d-flex flex-wrap gap-3 flex-lg-nowrap'>
                            <a href='#Form'>  <button
                                variant="dark"
                                className="d-flex align-items-center justify-content-center w-100 py-2 rounded-md PricePlanStyle  pdt-btn"
                            >
                                <span className="me-2">BOOK NOW</span>
                                <FaArrowRightLong size={17} />
                            </button></a>

                            <a href={`tel:${PhoneNumber}`}> <button

                                variant="dark"
                                className="d-flex align-items-center justify-content-center w-100 py-2 rounded-md PricePlanStyle pdt-btn"
                            >
                                <span className="me-2">CALL NOW</span>
                                <FaArrowRightLong size={17} />
                            </button></a>
                        </div>
                    </div>

                    <div className="col-12 col-lg-6">
                        <img src={AboutUsImg} style={{ width: "90%" }} />
                        <h4>Book Outstation Trips with Lucky One Way Taxi
                        </h4>
                    </div>


                </div>
            </div>


        </div>
    )
}

export default About