import React from 'react'
import { Ri24HoursLine } from "react-icons/ri";
import { HiOutlineCurrencyRupee } from "react-icons/hi";
import { PiTaxiFill } from "react-icons/pi";
import { GiPathDistance } from "react-icons/gi";
import whyChooseUsImg from "../img/why_choose_us.png"
import { FaStar } from "react-icons/fa6";




const WhyChooseUs = () => {
    return (
        <div>
            <section class="why-choose-us active">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-6">
                            <div class="finix-text">
                                <h2 >WHY CHOOSE US       </h2>
                                <div className='dividerMain'><div class="divider"><FaStar className='iconStyle' /></div></div>

                                <img src={whyChooseUsImg} alt="" style={{ width: "90%" }} />
                                {/* <div class="ct-btn">
                                    <a href="#" class=""><FaRegCirclePlay className='wcuIconStyle' /></a>
                                </div> */}
                            </div>
                        </div>
                        <div class="col-xl-6">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="feature-box mt-4">
                                        <div class="feature-box-content text-center">
                                            <div class="fbc-btn">
                                                <Ri24HoursLine className='wcuIconStyle' />

                                            </div>
                                            <h3 className='mt-5'>Pay Only for the Drop</h3>
                                            <p>Save big with our unique pricing model! With Lucky One Way Taxi, you only pay for the distance you travel, with no hidden return charges.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="feature-box">
                                        <div class="feature-box-content text-center">
                                            <div class="fbc-btn">
                                                <HiOutlineCurrencyRupee className='wcuIconStyle' />
                                            </div>
                                            <h3>Wide Service Network
                                            </h3>
                                            <p>We connect you to all major cities and towns across Tamil Nadu, Pondicherry, and Bangalore.
                                            </p>
                                        </div>
                                    </div>
                                </div><div class="col-lg-6">
                                    <div class="feature-box">
                                        <div class="feature-box-content text-center">
                                            <div class="fbc-btn">
                                                <PiTaxiFill className='wcuIconStyle' />
                                            </div>
                                            <h3>Safe and Comfortable Rides
                                            </h3>
                                            <p>Our fleet of well-maintained vehicles and experienced drivers guarantee your safety and comfort on every journey.
                                            </p>
                                        </div>
                                    </div>
                                </div><div class="col-lg-6">
                                    <div class="feature-box active-feature">
                                        <div class="feature-box-content text-center">
                                            <div class="fbc-btn">
                                                <GiPathDistance className='wcuIconStyle' />

                                            </div>
                                            <h3>24/7 Availability
                                            </h3>
                                            <p>Travel at your convenience, anytime, anywhere, with our round-the-clock taxi services.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default WhyChooseUs