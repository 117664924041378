import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import MainSEOList from './MainSEOList';
import CityRoutesList from './CityRoutesList';
import { WebsiteName, WebsiteUrl, PhoneNumber } from '../config/config';
import { Link } from 'react-router-dom';
import { FaArrowRightLong } from 'react-icons/fa6';
import { FaCar } from "react-icons/fa";
import Testimonials from './Testimonials';
import Banner from '../Form/Banner';
import Form from '../Form/Form';
import taxibanner from './img/bannerImg.png'


const MainSEO = () => {
    const { City, Routes } = useParams();  // Get the cityName from the URL
    useEffect(() => {
        window.scrollTo(10, 10); // Scroll to the top of the page when the component is mounted
    }, []);

    // Find the city data from CityRoutesList based on the city name
    const cityData = CityRoutesList.find(city => city.city.toLowerCase() === City.toLowerCase());
    console.log(cityData);

    if (!cityData) {
        return <h2>City not found</h2>;  // If the city is not found in the list, display a "City not found" message
    }

    // const [startCity, endCity] = Routes.toLowerCase() ? Routes.split('-to-') : []; // Parse Routes to get start and end cities



    // Find corresponding SEO content for the city (filter MainSEOList by city)
    const seoData = MainSEOList.find(item => item.city.toLowerCase() === City.toLowerCase());

    if (!seoData) {

        return <div> <Banner />
            <h2> SEO data not found for {City}</h2>
        </div>; // If SEO data is not found for the city, show an error message
    }

    function capitalizeWords(string) {
        const validString = string || ''; // Ensure it is always a string
        return validString
            .replace(/-/g, ' ') // Replace all hyphens with spaces
            .split(' ') // Split by space
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
            .join(' '); // Join words back with spaces
    }



    return (
        <div className='container pt-lg-3 pb-5 p-6 SEOMultiPage'>
            <div className='row'>
                {/* City One-Way Taxi Title */}
                <h1 className='fw-bolder text-center'>{capitalizeWords(City)} One-Way Taxi</h1>

                <div className='seoback row'>
                    <div className='col-12 col-md-6 col-lg-6'>
                        <Form />
                    </div>
                    <div className='col-12 col-md-6 col-lg-6'>
                        <img className='seoimgbanner' src={taxibanner} />
                    </div>
                </div>
                <div>

                    {/* SEO Data for the City */}
                    <p className='SEOtitle mt-3 pt-3'>{seoData.title}</p>
                    {/* <p className='SEODescrpition pt-1'>{seoData.description}</p> */}
                    <p className='SEODescrpition pt-1'>{seoData.description}</p>



                    {/* Why Choose Section */}
                    {seoData.WhyChooseHeading && seoData.WhyChooseDesc && (
                        <>
                            <h4 className='SEOSubtitle'>{seoData.WhyChooseHeading}</h4>
                            <p>{seoData.WhyChooseDesc}</p>
                        </>
                    )}

                    {/* Benefits List */}
                    {seoData.BenefitsHeading && seoData.BenefitsDesc && seoData.BenefitsDesc.length > 0 && (
                        <>
                            <h3 className='SEOSubtitle mt-3'>{seoData.BenefitsHeading}</h3>
                            <ol>
                                {seoData.BenefitsDesc.map((benefit, index) => (
                                    <li key={index}>
                                        <h5 className='SEOSubtitle'>{benefit.title}</h5>
                                        <p className='pt-3'>{benefit.description}</p>
                                    </li>
                                ))}
                            </ol>
                        </>
                    )}

                    {/* Popular Routes for the City */}
                    <div className=''>
                        <h2 className='SEOtitle mt-3 pt-3'>{seoData.PopularRoutesHeading}</h2>
                        <p className='text-center mt-3'>{seoData.PopularRoutesDesc1} </p>


                        <div className="row">
                            {cityData.routes.map((route, index) => (
                                cityData.city === route ? null : (
                                    <div className="col-12 col-sm-6 col-lg-4" key={index}>
                                        <div className="card SEOcard mb-3 text-center">
                                            <div className="card-body">
                                                <Link to={`/${cityData.city}/${cityData.city}-to-${route}`} className="text-decoration-none">
                                                    <h5 className='CityHeading'>{cityData.city} to {route} one way drop taxi</h5>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                )
                            ))}
                        </div>
                        <p className='mt-3 text-center'>{seoData.PopularRoutesDesc2} </p>
                    </div>

                    {/* How to Book Section */}
                    {seoData.BookingStepsHeading && seoData.BookingStepsDesc && seoData.BookingSteps && seoData.BookingSteps.length > 0 && (
                        <>
                            <p className='SEOSubtitle mt-4'>{seoData.BookingStepsHeading}</p>
                            <p className='mt-3'>{seoData.BookingStepsDesc}</p>

                            <ul>
                                {seoData.BookingSteps.map((step, index) => (
                                    <li className='d-lg-flex gap-3' key={index}>
                                        <span className='fw-bold'>{step.title}</span> <p>{step.description}</p>
                                    </li>
                                ))}
                            </ul>
                        </>
                    )}

                    {/* Customer Testimonials Section
                    <h3 className='SEOSubtitle mt-3'>{seoData.CustomerTestimonials}</h3>
                    {seoData.CustomerTestimonialsDes.map((testimonial, index) => (
                        <p key={index} className="SEODescrpition">{testimonial.testimonial}</p>
                    ))} */}
                    <Testimonials />

                    <div className="container lookMain px-5">
                        <div className="row">
                            {/* <h3 className='SEOtitle'>Contact Us</h3> */}
                            <div className='col-12 col-lg-6'>
                                <p>Have questions or need assistance with your one-way taxi booking? Reach out to us at Lucky One Way Taxi, and we will be happy to assist you. You can contact us through the website or call our customer service team.
                                </p>
                            </div>
                            <div className='col-12 col-lg-6 text-lg-end text-sm-center pt-lg-4'>
                                <h3><a href={`tel:${PhoneNumber}`} className='lookSubContent' >        {PhoneNumber}   </a></h3>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MainSEO;
