import React from 'react';
import { MdOutlineAirlineSeatReclineExtra } from 'react-icons/md';
import { MdLuggage } from 'react-icons/md';
import { PiOfficeChairLight } from 'react-icons/pi';
import { FaArrowRightLong } from 'react-icons/fa6';
import { Card, Row, Col } from 'react-bootstrap'; // Importing React Bootstrap components
import { WhatsappCode, PhoneNumber } from '../config/config';
import Call from '../img/Call.png'


const PricePlanComponents = ({ place, image, oneway, roundtrip }) => {
  return (

    <div className='col-12 col-lg-4 col-md-6 PricePlanStyle'>
      <div className='card pb-3'>
        <img className='PricePlanImg pt-5 ps-5 pb-4' src={image} />
        <div className='card-body'>
          <h2 className='card-title'>{place}</h2>
          <div className='row'>
            <div className='col-6 pricPlanSubcol  p-2'>
              <h5>One Way</h5>
              <h3>₹{oneway}</h3>
              <h6>PER KM</h6>
            </div>
            <div className='col-6 pricPlanSubcol p-2'>
              <h5>Round Trip</h5>

              <h3>₹{roundtrip}</h3>
              <h6>PER KM</h6>
            </div>
          </div>
        </div>
        <div className='d-lg-flex d-flex flex-wrap gap-3 flex-lg-nowrap'>

          <button

            variant="dark"
            className="d-flex align-items-center justify-content-center w-100 py-2 rounded-md pdt-btn"
          ><a href='#Form'>
              <span className="me-2">Book Now</span>
              {/* <img src={Call} size={17} /> */}

              <FaArrowRightLong size={17} />



            </a>    </button>

          <button
            variant="dark"
            className="d-flex align-items-center justify-content-center w-100 py-2 rounded-md pdt-btn"
          ><a href={`tel:${PhoneNumber}`}>
              <span className="me-2">CALL NOW</span>
              <FaArrowRightLong size={17} />
            </a>    </button>
        </div>
      </div>
    </div>

  );
};

export default PricePlanComponents;
