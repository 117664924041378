import 'bootstrap/dist/css/bootstrap.min.css';
import logo from "../img/luckyonewaytaxi_logo.png"
import { WhatsappCode, PhoneNumber } from '../config/config';
import { IoCall } from "react-icons/io5";


function MenuBar() {
    return (
        <div className="MenuBar sticky-top">
            <nav className='navbar navbar-expand-xl '>
                <div className='container'>
                    <a className='navbar-brand logoStyle' href='/'>
                        <img className='logoStyle' src={logo} />
                    </a>
                    <button className='navbar-toggler' type='button' data-bs-toggle="collapse" data-bs-target="#navbarToggler">
                        <span className='navbar-toggler-icon'></span>
                    </button>
                    <div className='collapse navbar-collapse  ' id='navbarToggler'>
                        <ul className='navbar-nav ms-lg-5 ps-lg-3 align-items-center'>
                            <li className='nav-item'><a className='nav-link' href='/' aria-current="page" >Home</a></li>
                            <li className='nav-item'><a className='nav-link' href='#About'>About</a></li>
                            <li className='nav-item'><a className='nav-link' href='#Tariff'>Tariff</a></li>
                            <li className='nav-item'><a className='nav-link' href='/drop-taxi-cities'>Drop Taxi Cities</a></li>
                            <li className='nav-item'><a className='nav-link' href='#Contact'>Contact</a></li>
                            <a href="#Form"><button
                                variant="dark"
                                className="pdt-btn"
                            ><span className="me-2">Book Now</span>
                            </button></a>
                        </ul>
                    </div>
                </div>
            </nav>

            <div className=' bg-dark'>
                <div className='d-xl-flex '>
                    <button className='offer-btn'>Offers</button>
                    <div className='d-flex text-center align-items-center'>
                        <marquee className="text-white text-center scrolling-text">
                            <span className='px-5'>Chennai to Banglore Rs.5599/-</span>
                            <span className='px-5'>Chennai to Madurai Rs.6499/-</span>
                            <span className='px-5'>Chennai to Salem Rs.4799/-</span>
                            <span className='px-5'>Chennai to Coimbatore Rs.7099/-</span>
                            <span className='px-5'>Chennai to Trichy Rs.8699/-</span>
                            <span className='px-5'>Chennai to Tirunelveli Rs.8899/-</span>
                            <span className='px-5'>Chennai to Tiruppur Rs.6599/-</span>
                            <span className='px-5'>Madurai to Pondicherry Rs.4999/-</span>
                            <span className='px-5'>Chennai to Pondicherry Rs.2199/-</span>
                        </marquee>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default MenuBar;
