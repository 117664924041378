import React from 'react';
import { PhoneNumber } from '../config/config';


const Look = () => {
    return (
        <div className="container lookMain">
            <div className="row">
                <div className='col-12 col-lg-6'>
                    <h3>Still Looking for an Drop Taxi ?</h3>
                </div>
                <div className='col-12 col-lg-6 text-end'>
                    <h3><a href={`tel:${PhoneNumber}`} className='lookSubContent' >        {PhoneNumber}   </a></h3>

                </div>
            </div>
        </div>
    );
};

export default Look;
