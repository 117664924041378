import React from 'react';
import { Popular, sendWhatsappMessage } from './PopularDropTaxiList';
import { FaStar } from "react-icons/fa6";
import { PhoneNumber } from '../config/config';


const PopularDropTaxi = () => {

  const BookNow = async (whatsappMessage) => {
    try {
      sendWhatsappMessage(whatsappMessage);
    } catch (error) {
      console.log("Failed to send WhatsApp message, please try again.");
    }
  }

  return (
    <div id='PopularDropTaxi' className='container mt-5 pt-2'>
      <h2 className='mt-4'>POPULAR DROP TAXI </h2>
      {/* <PopularHead /> */}
      <div className='dividerMain'><div class="divider"><FaStar className='iconStyle' /></div></div>
      <div className="row">
        {Popular.map((item, index) => (
          <div key={index} className='col-12 col-md-6 col-lg-6 col-xl-4 PopularDTmain'>
            <div className='d-flex  gap-3 '>
              {/* Dynamically set the image source */}
              <img src={item.img} alt={item.place} className='PopularDropTaxiImg' />
              <div className='ml-3 text-justify'>
                <div className='pdt-content'>
                  <a href={item.link} >  <p>{item.place}</p></a>
                  <p>Starting from - {item.amount}</p>

                </div>
                <div className='d-flex flex-lg-nowrap flex-wrap gap-3'>
                  <button className='pdt-btn' id='BookNow' onClick={() => BookNow(item.whatsappMessage)}>
                    <a href={`https://wa.me/${item.whatsappCode}?text=`} target="_blank" rel="noopener noreferrer">
                      Book Now
                    </a>
                  </button>
                  <button className='pdt-btn'>
                    <a href={`tel:${PhoneNumber}`}>
                      Call Now
                    </a>
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PopularDropTaxi;
