import React, { useState, useRef, useEffect } from 'react';
// Icons
import { FaUser, FaMobile, FaLocationArrow, FaMapMarkerAlt, FaCalendar, FaArrowLeft } from "react-icons/fa";
// Images
import SedanBgNone from "../img/sedanBgNone.png";
import SedanWithBg from "../img/sedanwithbg.png";
import EtiosBgNone from "../img/etiosbgnone.png";
import EtiosWithBg from "../img/etioswithbg.png";
import SuvBgNone from "../img/suvBgNone.png";
import SuvWithBg from "../img/suvwithbg.png";
import InnovaBgNone from "../img/InnovaBgNone.png";
import InnovaWithBg from "../img/innovawithbg.png";
// Date Picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// Google Maps API
import { GoogleMap, StandaloneSearchBox, useLoadScript } from "@react-google-maps/api";
// Custom functions
import { sendTelegramMessage, sendWhatsappMessage, sendEmailMessage } from './sendMessage';
import { telegramChatId, telegramid, WhatsappCode, TelegramApi, ChatId } from '../config/config';
import axios from 'axios';
import { Tooltip } from 'react-tooltip';
// React Hook Form
import { useForm } from 'react-hook-form';

const OutStationOneWay = () => {
    const [startDate, setStartDate] = useState(null);
    const [listCars, setListCars] = useState({ car_amount: 0 });
    const [carImages, setCarImages] = useState(null);
    const [driverBeta, setDriverBeta] = useState(400);
    const [totRate, setTotalRate] = useState(0);
    const [errorMessage, setErrorMessage] = useState("");
    const [TotalDistance, setTotalDistance] = useState('');
    const [TotalDuration, setTotalDuration] = useState('');
    const [google, setGoogle] = useState(null);
    const [distanceResult, setDistanceResult] = useState({ org_distance: null, org_duration: null });
    const [isClicked, setIsClicked] = useState(false);
    const [formHide, setFormHide] = useState(true);
    const [name, setName] = useState('');
    const [mobile, setMobile] = useState();
    const [pickupCity, setPickUpCity] = useState('');
    const [destinationCity, setdestinationCity] = useState('');
    const [pickUpTime, setPickUpTime] = useState('');
    const [formData, setFormData] = useState('');

    const { register, handleSubmit, formState: { errors }, setValue, clearErrors, reset } = useForm();
    const searchBox = useRef(null);
    const formRef = useRef(null);

    useEffect(() => {
        if (window.google) {
            setGoogle(window.google);
        }
    }, []);

    // Function to select a car and update state
    const selectCar = (text, car_amount, carDriverBeta) => {
        if (car_amount) {
            clearErrors('selectCarType'); // Clear the error for selectCarType
        }
        setListCars({ text, car_amount });
        setDriverBeta(carDriverBeta);
        setCarImages(text);
        setValue('selectCarType', text);
    };

    // Handle places changed on Google Map
    const handlePlacesChanged = () => {
        const places = searchBox.current.getPlaces();
    };

    // Submit form data and calculate distance/duration
    const onSubmit = async (data) => {
        window.scrollTo(10, 10);
        setFormData({ ...data });
        setIsClicked(true);
        setFormHide(false);

        if (!google) {
            console.log("Google Maps API is not loaded yet.");
            return;
        }

        const distanceMatrixService = new google.maps.DistanceMatrixService();
        const distanceMatrixOptions = {
            origins: [data.SelectCity],
            destinations: [data.DestinationCity],
            travelMode: 'DRIVING'
        };

        // Fetch distance matrix
        await distanceMatrixService.getDistanceMatrix(distanceMatrixOptions, async (response, status) => {
            if (status === "OK" && response.destinationAddresses.length > 0 && response.rows.length > 0) {
                const element = response.rows[0].elements[0];
                if (element.distance && element.duration) {
                    var org_distance = element.distance.text;
                    var org_duration = element.duration.text;

                    setTotalDistance(org_distance);
                    setTotalDuration(org_duration);
                    setDistanceResult({ org_distance, org_duration });

                    // Calculate rate based on distance and car type
                    var one_way_rate = parseInt(org_distance) * parseInt(listCars.car_amount) + driverBeta;
                    setTotalRate(one_way_rate);
                    if (parseInt(org_distance) <= 130) {
                        one_way_rate = 130 * parseInt(listCars.car_amount) + driverBeta;
                        setTotalRate(one_way_rate);
                    }

                    const localDateString = startDate.toLocaleDateString('en-GB');
                    try {
                        await sendTelegramMessage(telegramid, telegramChatId, TelegramApi, ChatId, org_distance, org_duration, one_way_rate, driverBeta, data, localDateString);
                        console.log("WhatsApp message sent successfully!");
                    } catch (error) {
                        console.log("Failed to send WhatsApp message, please try again.");
                    }

                    // Send email if needed
                    try {
                        const sendEmail = sendEmailMessage();
                        const result = await sendEmail(org_distance, org_duration, one_way_rate, driverBeta, data, localDateString);
                        if (result) {
                            console.log("Email Message sent successfully!");
                        } else {
                            console.log("Failed to send email message.");
                        }
                        console.log(JSON.stringify(formData) + " log Form Data");


                    } catch (error) {
                        console.log("Failed to send Email Message, please try again.");


                    }

                } else {
                    setErrorMessage("Distance or duration not available");
                }
            } else {
                console.error("Unexpected response structure", response);
            }
        });
    };

    // Confirm the booking and send messages
    const conformBooking = async (data) => {
        const localDateString = startDate.toLocaleDateString('en-GB');
        try {
            await sendWhatsappMessage(TotalDistance, TotalDuration, totRate, driverBeta, formData, localDateString);
            console.log("WhatsApp message sent successfully!");
        } catch (error) {
            console.log("Failed to send WhatsApp message, please try again.");
        }


    };

    // Handle previous button click
    const handlePreviousClick = () => {
        setIsClicked(false); // Hide the summary
        setFormHide(true); // Show the form again
    };

    return (
        <>
            <div>
                {formHide &&
                    <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
                        <div className='container-fluid formMain'>
                            <div className='row py-2 homeforminput'>
                                <div className='position-relative'>
                                    <input className="form-control" id='firstName' name='firstName' type='text' placeholder='Enter Full Name'
                                        {...register('firstName', { required: 'Name field is required' })}
                                    // value={name} onChange={(e) => setName(e.target.value)} 
                                    />
                                    <FaUser className="position-absolute input-icon" />
                                    {errors.firstName && <p className='errorMes'>{errors.firstName.message}</p>}
                                </div>

                                <div className=' position-relative'>
                                    <span className='numcodedis  position-absolute'>🇮🇳 </span>
                                    <input className="form-control mbnumd" type='text' placeholder='Mobile Number'
                                        // value={mobile} onChange={(e) => setMobile(e.target.value)}
                                        {...register('mobileNum', {
                                            required: 'Mobile Number field is required',
                                            minLength: {
                                                value: 4,
                                                message: 'Mobile Number must be at least 4 digits'
                                            },
                                            maxLength: {
                                                value: 15,
                                                message: 'Mobile Number must not exceed 15 digits'
                                            },
                                            pattern: {
                                                value: /^[0-9]*$/, // Only digits
                                                message: 'Mobile Number must contain only digits'
                                            }
                                        })} />
                                    <FaMobile
                                        className="position-absolute input-icon" />
                                    {errors.mobileNum && <p className='errorMes'>{errors.mobileNum.message}</p>}
                                </div>

                                <div className='position-relative'>
                                    <StandaloneSearchBox
                                        onLoad={(ref) => (searchBox.current = ref)}
                                        onPlacesChanged={handlePlacesChanged}>
                                        <input className="form-control" type='text' id='SelectCity' name='SelectCity' placeholder='Enter pickup city'
                                            {...register('SelectCity', { required: 'Field is required' })}
                                        // value={pickupCity} onChange={(e) => setPickUpCity(e.target.value)} 
                                        />
                                    </StandaloneSearchBox>
                                    <FaLocationArrow className="position-absolute input-icon" />
                                    {errors.SelectCity && <p className='errorMes'>{errors.SelectCity.message}</p>}
                                </div>

                                <div className='position-relative'>
                                    <StandaloneSearchBox
                                        onLoad={(ref) => (searchBox.current = ref)}
                                        onPlacesChanged={handlePlacesChanged}>

                                        <input className="form-control" type='text' id='DestinationCity' name='DestinationCity' placeholder='Enter destination city'
                                            {...register('DestinationCity', { required: 'Field is required' })}
                                        // value={destinationCity} onChange={(e) => setdestinationCity(e.target.value)}
                                        />
                                    </StandaloneSearchBox>
                                    <FaMapMarkerAlt className="position-absolute input-icon" />
                                    {errors.DestinationCity && <p className='errorMes'>{errors.DestinationCity.message}</p>}
                                </div>

                                <div className='d-flex flex-lg-nowrap flex-wrap datepickclsmain'>
                                    <div className=' datepickcls'>
                                        <div className=' position-relative'>
                                            <DatePicker
                                                selected={startDate}
                                                onChange={(date) => {
                                                    setStartDate(date);
                                                    setValue('startDatef', date);
                                                }}
                                                dateFormat="dd/MM/yyyy"
                                                minDate={new Date()}
                                                placeholderText="Pickup Date"
                                                inputMode='none'
                                                onFocus={(e) => e.target.blur()}
                                                className='custom-datepicker'
                                            />
                                            <input
                                                type="hidden"
                                                value={startDate ? startDate.toISOString() : ''}
                                                name="startDatef"
                                                id='startDatef'
                                                onChange={(e) => setStartDate(e.target.value)}
                                                {...register('startDatef', { required: 'Date field is required' })} />
                                            <FaCalendar className="position-absolute input-icon FaCalendarcls" />
                                            {!startDate && errors.startDatef && <p className='errorMesdt'>{errors.startDatef.message}</p>}
                                        </div>
                                    </div>
                                    <div className=' datepickcls'>
                                        <div className='position-relative'>

                                            <select
                                                id='startTime' placeholder='Time' name='startTime' className='custom-datepicker'
                                                {...register('startTime', { required: 'Time Field is required' })}
                                            >
                                                <option value="">Pickup Time</option>

                                                <option value="12:00 AM">12:00 AM</option>

                                                <option value="12:30 AM">12:30 AM</option>

                                                <option value="01:00 AM">01:00 AM</option>

                                                <option value="01:30 AM">01:30 AM</option>

                                                <option value="02:00 AM">02:00 AM</option>

                                                <option value="02:30 AM">02:30 AM</option>

                                                <option value="03:00 AM">03:00 AM</option>

                                                <option value="03:30 AM">03:30 AM</option>

                                                <option value="04:00 AM">04:00 AM</option>

                                                <option value="04:30 AM">04:30 AM</option>

                                                <option value="05:00 AM">05:00 AM</option>

                                                <option value="05:30 AM">05:30 AM</option>

                                                <option value="06:00 AM">06:00 AM</option>

                                                <option value="06:30 AM">06:30 AM</option>

                                                <option value="07:00 AM">07:00 AM</option>

                                                <option value="07:30 AM">07:30 AM</option>

                                                <option value="08:00 AM">08:00 AM</option>

                                                <option value="08:30 AM">08:30 AM</option>

                                                <option value="09:00 AM">09:00 AM</option>

                                                <option value="09:30 AM">09:30 AM</option>

                                                <option value="10:00 AM">10:00 AM</option>

                                                <option value="10:30 AM">10:30 AM</option>

                                                <option value="11:00 AM">11:00 AM</option>

                                                <option value="11:30 AM">11:30 AM</option>

                                                <option value="12:00 PM">12:00 PM</option>

                                                <option value="12:30 PM">12:30 PM</option>

                                                <option value="01:00 PM">01:00 PM</option>

                                                <option value="01:30 PM">01:30 PM</option>

                                                <option value="02:00 PM">02:00 PM</option>

                                                <option value="02:30 PM">02:30 PM</option>

                                                <option value="03:00 PM">03:00 PM</option>

                                                <option value="03:30 PM">03:30 PM</option>

                                                <option value="04:00 PM">04:00 PM</option>

                                                <option value="04:30 PM">04:30 PM</option>

                                                <option value="05:00 PM">05:00 PM</option>

                                                <option value="05:30 PM">05:30 PM</option>

                                                <option value="06:00 PM">06:00 PM</option>

                                                <option value="06:30 PM">06:30 PM</option>

                                                <option value="07:00 PM">07:00 PM</option>

                                                <option value="07:30 PM">07:30 PM</option>

                                                <option value="08:00 PM">08:00 PM</option>

                                                <option value="08:30 PM">08:30 PM</option>

                                                <option value="09:00 PM">09:00 PM</option>

                                                <option value="09:30 PM">09:30 PM</option>

                                                <option value="10:00 PM">10:00 PM</option>

                                                <option value="10:30 PM">10:30 PM</option>

                                                <option value="11:00 PM">11:00 PM</option>

                                                <option value="11:30 PM">11:30 PM</option>
                                            </select>
                                            {errors.startTime && <p className='errorMesdt'>{errors.startTime.message}</p>}
                                        </div>
                                    </div>
                                </div>

                            </div>

                            {/* Car Select */}
                            <div className='container'>
                                <div className='row gap-2 selectCarMaincls'>
                                    <div className='carSelectHeading'><h4>Choose Vehicle</h4> </div>
                                    <input type='hidden' {...register('selectCarType', { required: 'Car field is required' })}
                                    />
                                    <div className={` 'col-5 col-lg-2 selectCarcls text-center' ${carImages === 'SEDAN' ? 'selectedImg' : ''}`}>
                                        <span className="">14₹/km</span>
                                        <img className='selectCarImg'
                                            src={SedanBgNone}
                                            alt="sedan"
                                            // onChange={setValue('selectCarType', event)}

                                            onClick={() => selectCar("SEDAN", 14, 300)}
                                        />
                                        <span className="">SEDAN</span>
                                    </div>
                                    <div className={` 'col-5 col-lg-2 selectCarcls text-center' ${carImages === 'ETIOS' ? 'selectedImg' : ''}`}>
                                        <span className="">14₹/km</span>
                                        <img className='selectCarImg'
                                            src={EtiosBgNone}
                                            alt="Etios"
                                            onClick={() => selectCar("ETIOS", 14, 300)}
                                        />
                                        <span className="">ETIOS</span>
                                    </div>
                                    <div className={` 'col-5 col-lg-2 selectCarcls text-center image-container' ${carImages === 'SUV' ? 'selectedImg' : ''}`}>
                                        <span>19₹/km</span>
                                        <img className='selectCarImg' src={SuvBgNone}
                                            onClick={() => selectCar("SUV", 19, 400)} />
                                        <span>SUV</span>
                                    </div>
                                    <div className={` 'col-5 col-lg-2 selectCarcls text-center image-container' ${carImages === 'INNOVA' ? 'selectedImg' : ''}`}>
                                        <span>20₹/km</span>
                                        <img className='selectCarImg'
                                            src={InnovaBgNone}
                                            alt='INNOVA'
                                            onClick={() => selectCar("INNOVA", 20, 400)} />
                                        <span>INNOVA</span>
                                    </div>

                                </div>
                                {errors.selectCarType && <p className="errorMes">{errors.selectCarType.message}</p>}
                            </div>
                            {errorMessage && <p className='errorMes'>{errorMessage}</p>}
                            {/* Find Cab Button */}
                            <div className=' py-3'>

                                <button className='findcabbtn ' >
                                    FIND CABS
                                </button>
                            </div>
                        </div>
                    </form >
                }
            </div>



            {isClicked && (
                <div className='mt-3 rounded-5 formMain'>
                    <table class="table">
                        <tbody>
                            <tr>
                                <th scope="row">Total Distance</th>
                                <td>{TotalDistance}</td>
                            </tr>
                            <tr>
                                <th scope="row">Total Duration: </th>
                                <td>{TotalDuration} </td>
                            </tr>
                            <tr>
                                <th scope="row">Total Rate: </th>
                                <td>{totRate} (Driver Beta Included)</td>
                            </tr>
                            <tr>
                                <th scope="row">Driver Beta: </th>
                                <td>{driverBeta}</td>
                            </tr>
                            <tr>
                                <th scope="row" >Extra Charges</th>
                                <td>Toll Gate, State, Hill Station - Permit Extra </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className=' py-3 px-5' >
                        <div >
                            <button className='findcabbtn' id='conformBooking' onClick={conformBooking}>
                                Confirm Booking
                            </button>
                        </div>
                    </div>
                </div >
            )
            }
        </>
    )
}

export default OutStationOneWay