import './App.css';
import MenuBar from './Pages/MenuBar';
// index.js or App.js
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Banner from './Form/Banner';
import About from './Pages/About';
import OurServices from './Pages/OurServices';
import PricePlan from './Pages/PricePlan'
import WhyChooseUs from './Pages/WhyChooseUs';
import Look from "./Pages/look";
import PopularDropTaxi from './PopluarDropTaxi/PopularDropTaxi';
import FamousTaxi from './SEO/FamousTaxi';

function Home() {
  return (
    <div className="Home">
      <Banner />
      <About />
      <OurServices />

      <PopularDropTaxi />
      <FamousTaxi />
      <PricePlan />
      <WhyChooseUs />
      <Look />



    </div>
  );
}

export default Home;
