import React from "react";
import sedan from "../img/cars/sedan.jpeg";
import etios from "../img/cars/etios-car-sedan.png";
import suv from "../img/cars/Mahindra-Xylo-suv.jpeg";
import innova from "../img/cars/innova-suv.png";
import PricePlanComponents from "../Pages/PricePlanComponents";
import Slider from "react-slick";
import { FaStar } from "react-icons/fa6";


// Import slick-carousel styles
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const PricePlan = () => {
  // Slick carousel settings for 4 items per slide
  const settings = {
    dots: false, // Hides the bottom navigation dots
    infinite: true,
    speed: 200,
    slidesToShow: 3, // Shows 4 items per slide
    slidesToScroll: 1,
    arrows: false, // Hides the arrow buttons
    autoplay: false, // Enables autoplay
    autoplaySpeed: 2000, // Autoplay speed in milliseconds
    responsive: [
      {
        breakpoint: 1024, // For screens >= 1024px (desktops)
        settings: {
          slidesToShow: 2, // Shows 3 items per slide
        },
      },
      {
        breakpoint: 768, // For screens >= 768px (tablets)
        settings: {
          slidesToShow: 1, // Shows 2 items per slide
        },
      },
      {
        breakpoint: 480, // For screens >= 480px (mobiles)
        settings: {
          slidesToShow: 1, // Shows 1 item per slide
        },
      },
    ],
  };

  return (
    <div className="bg-light priceplanMain" id="Tariff">
      <div className="container py-5">
        <h2 >PRICING PLAN          </h2>
        <div className='dividerMain'><div class="divider"><FaStar className='iconStyle' /></div></div>

        <div className="display-4 font-weight-bold mt-4 text-center">
          Our Most Valuable Drop Taxi Pricing
        </div>

        <div className="mt-5">
          <Slider {...settings}>
            {/* First Box */}
            <div>
              <PricePlanComponents
                place="SEDAN"
                image={sedan}
                oneway="14"
                roundtrip="13"
                passengers="4"
                luggage="4"
              />
            </div>
            {/* Second Box */}
            <div>
              <PricePlanComponents
                place="ETIOS"
                image={etios}
                oneway="14"
                roundtrip="13"
                passengers="4"
                luggage="2"
              />
            </div>
            {/* Third Box */}
            <div>
              <PricePlanComponents
                place="SUV"
                image={suv}
                oneway="19"
                roundtrip="18"
                passengers="7"
                luggage="4"
              />
            </div>
            {/* Fourth Box */}
            <div>
              <PricePlanComponents
                place="INNOVA"
                image={innova}
                oneway="20"
                roundtrip="19"
                passengers="7"
                luggage="4"
              />
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default PricePlan;
