
import React from 'react';
import { Link } from 'react-router-dom';
import MaduraiImg from './img/1.jpg'; // Image placeholder
import ChennaiImg from './img/Chennai.jpg'
import TrichyImg from './img/trichy.jpg'
import SalemImg from './img/salem.jpg'
import BangaloreImg from './img/Bangalor.jpg'
import CoimbatoreImg from './img/coimbatore.jpeg'
import PondicherryImg from './img/Chennai.jpg'
import TirunelveliImg from './img/tirunelveli.jpg'
import ThanjavurImg from './img/Thanjavur.jpg'

const allRoutes = [
    'madurai', 'chennai', 'trichy', 'coimbatore', 'salem', 'thiruvannamalai', 'thanjavur', 'bangalore',
    'tuticorin', 'tirunelveli', 'kodaikanal', 'pudukottai', 'ooty', 'kanniyakumari', 'rameshwaram',
    'thiruchendur', 'vellore', 'pondicherry', 'karur', 'namakkal', 'erode', 'ramanad'
];




const CityRoutesList = [
    {
        city: 'madurai',
        routes: allRoutes,
        img: MaduraiImg
    },
    {
        city: 'chennai',
        routes: allRoutes,
        img: ChennaiImg
    },
    {
        city: 'trichy',
        routes: allRoutes,
        img: TrichyImg
    },
    {
        city: 'salem',
        routes: allRoutes,
        img: SalemImg
    },
    {
        city: 'bangalore',
        routes: allRoutes,
        img: BangaloreImg
    },
    {
        city: 'coimbatore',
        routes: allRoutes,
        img: CoimbatoreImg
    },
    {
        city: 'pondicherry',
        routes: allRoutes,
        img: PondicherryImg
    },
    {
        city: 'tirunelveli',
        routes: allRoutes,
        img: TirunelveliImg
    },
    {
        city: 'thanjavur',
        routes: allRoutes,
        img: ThanjavurImg
    }
];

export default CityRoutesList;