import React, { useEffect } from 'react';
import MaduraiImg from './img/1.jpg'; // Image placeholder
import ChennaiImg from './img/Chennai.jpg';
import TrichyImg from './img/trichy.jpg';
import SalemImg from './img/salem.jpg';
import BangaloreImg from './img/Bangalor.jpg';
import CoimbatoreImg from './img/coimbatore.jpeg';
import PondicherryImg from './../PopluarDropTaxi/PopularDropTaxiImg/pondicherry.png';
import TirunelveliImg from './img/tirunelveli.jpg';
import ThanjavurImg from './img/Thanjavur.jpg';
import { FaArrowRightLong } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import CityRoutesList from './CityRoutesList';
import MainSEOList from './MainSEOList';
import { IoCall } from "react-icons/io5";


import { WebsiteName, WebsiteUrl, PhoneNumber } from '../config/config'

const Drop_Taxi_CitiesProps = [
    { Title: 'Madurai Drop Taxi', Img: MaduraiImg, city: 'madurai' },
    { Title: 'Chennai Drop Taxi', Img: ChennaiImg, city: 'chennai' },
    { Title: 'Trichy Drop Taxi', Img: TrichyImg, city: 'trichy' },
    { Title: 'Salem Drop Taxi', Img: SalemImg, city: 'salem' },
    { Title: 'Bangalore Drop Taxi', Img: BangaloreImg, city: 'bangalore' },
    { Title: 'Coimbatore Drop Taxi', Img: CoimbatoreImg, city: 'coimbatore' },
    { Title: 'Pondicherry Drop Taxi', Img: PondicherryImg, city: 'pondicherry' },
    { Title: 'Tirunelveli Drop Taxi', Img: TirunelveliImg, city: 'tirunelveli' },
    { Title: 'Thanjavur Drop Taxi', Img: ThanjavurImg, city: 'thanjavur' },
];

const Drop_Taxi_Cities = () => {
    const { City, Routes } = useParams();  // Get the cityName from the URL

    const CityRouteData = CityRoutesList.find(cityData => cityData.city === City);

    useEffect(() => {
        window.scrollTo(10, 10);
    }, []);

    return (
        <div className='container pt-3 mt-3'>
            <div className='row'>
                {Drop_Taxi_CitiesProps.map((items, index) => {
                    // Find the city description
                    const seoDescription = MainSEOList.find(des => des.city.toLowerCase() === items.city.toLowerCase())?.description;

                    return (
                        <div className='col-12 col-md-6 col-lg-6 col-xl-4' key={index}>
                            <h2>{items.Title}</h2>
                            <div className='card'>
                                <img className='droptaxiImg' src={items.Img} alt={items.Title} />
                                <div className='card-body'>
                                    {/* Display SEO description if available */}
                                    {seoDescription ? (
                                        <p className='card-text '>{seoDescription}</p>
                                    ) : (
                                        <p className='card-text'>No description available</p>
                                    )}

                                    <div className='d-lg-flex d-flex flex-wrap gap-3 flex-lg-nowrap mb-3'>
                                        <Link variant="dark" className="d-flex align-items-center justify-content-center w-100 py-2 rounded-md pdtlink-btn" to={`/${items.city}`}>
                                            <button
                                                variant="dark"
                                                className="d-flex align-items-center justify-content-center w-100 py-2 rounded-md pdtlink-btn"
                                            >

                                                <span className="me-2">Visit</span>
                                                <FaArrowRightLong size={17} />                                        </button>
                                        </Link>

                                        <button
                                            variant="dark"
                                            className="d-flex gap-2 align-items-center w-100 py-2 rounded-md pdt-btn"
                                        >
                                            <a href={`tel:${PhoneNumber}`}>
                                                <IoCall size={17} className="me-2 mb-1" />

                                                <span className="me-2">+91 9600969362</span>
                                            </a>
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default Drop_Taxi_Cities;
