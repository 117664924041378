import React from 'react';
import CoimbatoreImg from '../PopluarDropTaxi/PopularDropTaxiImg/Coimbatore.png';
import SalemImg from '../PopluarDropTaxi/PopularDropTaxiImg/salem.png';
import Trichyimg from '../PopluarDropTaxi/PopularDropTaxiImg/trichy.png';
import PondicherryImg from '../PopluarDropTaxi/PopularDropTaxiImg/pondicherry.png';
import BangaloreImg from '../PopluarDropTaxi/PopularDropTaxiImg/Bangalore.png';
import MaduraiImg from '../PopluarDropTaxi/PopularDropTaxiImg/Madurai.png';
import TiruvannamalaiImg from '../PopluarDropTaxi/PopularDropTaxiImg/Tiruvannamalai.png';
import TirupatiImg from '../PopluarDropTaxi/PopularDropTaxiImg/Tirupati.png';
import OotyImg from '../PopluarDropTaxi/PopularDropTaxiImg/Ooty.png';
import TiruchirappalliImg from '../PopluarDropTaxi/PopularDropTaxiImg/trichy.png';
import DharmapuriImg from '../PopluarDropTaxi/PopularDropTaxiImg/Dharmapuri.png';
import PollachiImg from '../PopluarDropTaxi/PopularDropTaxiImg/Pollachi.png';


import { WhatsappCode } from '../config/config';

const sendWhatsappMessage = (whatsappMessage) => {
    const storeWhatsappMess = encodeURIComponent(whatsappMessage);

    window.open(`https://wa.me/${WhatsappCode}?text=${storeWhatsappMess}`, '_blank');
};

const Popular = [

    {
        link: '#SEOMainPage',
        img: CoimbatoreImg,
        place: 'Chennai to Coimbatore Taxi',
        amount: '₹ 7477',
        whatsappMessage: 'I would like to book a cab from Chennai to Coimbatore. Please share us the details'
    },
    {
        link: '#Tariff',
        img: SalemImg,
        place: 'Chennai to Salem Taxi',
        amount: '₹ 5230',
        whatsappMessage: 'I would like to book a cab from Chennai to Salem. Please share us the details'
    },
    {
        link: '#Tariff',
        img: Trichyimg,
        place: 'Chennai to Trichy Taxi',
        amount: '₹ 5045',
        whatsappMessage: 'I would like to book a cab from Chennai to Trichy. Please share us the details'
    },
    {
        link: '#Tariff',
        img: PondicherryImg,
        place: 'Chennai to Pondicherry Taxi',
        amount: '₹ 2710',
        whatsappMessage: 'I would like to book a cab from Chennai to Pondicherry. Please share us the details'
    },
    {
        link: '#Tariff',
        img: BangaloreImg,
        place: 'Chennai to Bangalore Taxi',
        amount: '₹ 5264',
        whatsappMessage: 'I would like to book a cab from Chennai to Bangalore. Please share us the details'
    },
    {
        link: '#Tariff',
        img: MaduraiImg,
        place: 'Chennai to Madurai Taxi',
        amount: '₹ 6872',
        whatsappMessage: 'I would like to book a cab from Chennai to Madurai Taxi. Please share us the details'
    },
    {
        link: '#Tariff',
        img: TiruvannamalaiImg,
        place: 'Chennai to Tiruvannamalai Taxi',
        amount: '₹ 3116',
        whatsappMessage: 'I would like to book a cab from Chennai to Tiruvannamalai Taxi. Please share us the details'
    },
    {
        link: '#Tariff',
        img: TirupatiImg,
        place: 'Chennai to Tirupati Taxi',
        amount: '₹ 2262',
        whatsappMessage: 'I would like to book a cab from Chennai to Tirupati Taxi. Please share us the details'
    },
    {
        link: '#Tariff',
        img: OotyImg,
        place: 'Chennai to Ooty Taxi',
        amount: '₹ 1597',
        whatsappMessage: 'I would like to book a cab from Chennai to Ooty Taxi. Please share us the details'
    },
    {
        link: '#Tariff',
        img: TiruchirappalliImg,
        place: 'Chennai to Tiruchirappalli Taxi',
        amount: '₹ 5024',
        whatsappMessage: 'I would like to book a cab from Chennai to Tiruchirappalli Taxi. Please share us the details'
    },
    {
        link: '#Tariff',
        img: DharmapuriImg,
        place: 'Chennai to Dharmapuri Taxi',
        amount: '₹ 4606',
        whatsappMessage: 'I would like to book a cab from Chennai to Dharmapuri Taxi. Please share us the details'
    },
    {
        link: '#Tariff',
        img: PollachiImg,
        place: 'Chennai to Pollachi Taxi',
        amount: '₹ 7788',
        whatsappMessage: 'I would like to book a cab from Chennai to Pollachi Taxi. Please share us the details'
    }

];

export { Popular, sendWhatsappMessage };
