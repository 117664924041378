import React from 'react';
import { Link } from 'react-router-dom';
import MaduraiImg from './img/1.jpg'; // Image placeholder
import ChennaiImg from './img/Chennai.jpg'
import TrichyImg from './img/trichy.jpg'
import SalemImg from './img/salem.jpg'
import BangaloreImg from './img/Bangalor.jpg'
import CoimbatoreImg from './img/coimbatore.jpeg'
import PondicherryImg from './../PopluarDropTaxi/PopularDropTaxiImg/pondicherry.png'
import TirunelveliImg from './img/tirunelveli.jpg'
import ThanjavurImg from './img/Thanjavur.jpg'


// Define the list of taxi routes
// Store all possible routes in one place
const allRoutes = [
    'Madurai', 'Chennai', 'Trichy', 'Coimbatore', 'Salem', 'Thiruvannamalai', 'thanjavur', 'Bangalore',
    'Tuticorin', 'Tirunelveli', 'Kodaikanal', 'Pudukottai', 'Ooty', 'kanniyakumari', 'Rameshwaram',
    'Thiruchendur', 'Vellore', 'Pondicherry', 'Karur', 'Namakkal', 'Erode', 'Ramanad'
];


// List of cities with routes referring to the common routes array
const FamousTaxiList = [
    {
        city: 'Madurai',
        routes: allRoutes,
        img: MaduraiImg
    },
    {
        city: 'Chennai',
        routes: allRoutes,
        img: ChennaiImg
    },
    {
        city: 'Trichy',
        routes: allRoutes,
        img: TrichyImg
    },
    {
        city: 'Salem',
        routes: allRoutes,
        img: SalemImg
    },
    {
        city: 'Bangalore',
        routes: allRoutes,
        img: BangaloreImg
    },
    {
        city: 'Coimbatore',
        routes: allRoutes,
        img: CoimbatoreImg
    },
    {
        city: 'Pondicherry',
        routes: allRoutes,
        img: PondicherryImg
    },
    {
        city: 'Tirunelveli',
        routes: allRoutes,
        img: TirunelveliImg
    },
    {
        city: 'Thanjavur',
        routes: allRoutes,
        img: ThanjavurImg
    }
];


console.log(FamousTaxiList);






export default FamousTaxiList;
