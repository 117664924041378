import React from "react";
import img2 from "../img/city_rides.jpg";
import img3 from "../img/airport-rides.jpg";
import img4 from "../img/intercity-travel.jpg";
import { FaStar } from "react-icons/fa6";
import { PhoneNumber } from '../config/config';



const OurServices = () => {
  return (


    <div id="ourservices" className="container mt-5">
      <h2 >OUR SERVICES   </h2>
      <div className='dividerMain mb-4'><div class="divider"><FaStar className='iconStyle' /></div></div>

      <div className="row">
        <div className="col-12 col-lg-3">
          <div className="card OurServiceCardMain border-2 p-4 rounded-3 flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img src={img3} className="card-img-top rounded-3" alt="Airport Transfers" />
                <h5 className="card-title">One-way drop taxi services</h5>
              </div>
              <div className="flip-card-back">
                <p className="card-text mt-2 pt-5">Need a drop-off at your destination without the hassle of booking a return trip? We’ve got you covered!</p>
                <span className="flipContact"><a className="text-white" href={`tel:${PhoneNumber}`}>{PhoneNumber}</a></span>

              </div>
            </div>
          </div>

        </div>
        <div className="col-12 col-lg-3">
          <div className="card OurServiceCardMain border-2 p-4 rounded-3 flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img src={img2} className="card-img-top rounded-3" alt="One-Way Taxi Services
" />
                <h5 className="card-title">Affordable and transparent pricing</h5>
              </div>
              <div className="flip-card-back">
                <p className="card-text mt-3 pt-5">Whether it’s a business trip or a family vacation, we provide safe and affordable long-distance travel options.</p>
                <span className="flipContact"><a className="text-white" href={`tel:${PhoneNumber}`}>{PhoneNumber}</a></span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-3">
          <div className="card OurServiceCardMain border-2 p-4 rounded-3 flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img src={img4} className="card-img-top rounded-3" alt="Long-Distance Travel
" />
                <h5 className="card-title">Door-to-door pickup and drop
                </h5>
              </div>
              <div className="flip-card-back">
                <p className="card-text mt-3 pt-5">Get to or from the airport comfortably with our reliable drop taxi service.</p>
                <span className="flipContact"><a className="text-white" href={`tel:${PhoneNumber}`}>{PhoneNumber}</a></span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-3">
          <div className="card OurServiceCardMain border-2 p-4 rounded-3 flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img src={img4} className="card-img-top rounded-3" alt="Long-Distance Travel
" />
                <h5 className="card-title">Customized travel Package
                </h5>
              </div>
              <div className="flip-card-back">
                <p className="card-text mt-3 pt-5">Customized travel solutions for families, solo travelers, and corporate clients</p>
                <span className="flipContact"><a className="text-white" href={`tel:${PhoneNumber}`}>{PhoneNumber}</a></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurServices;
