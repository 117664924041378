import React from 'react'
//Icon
import { FaUser } from "react-icons/fa";
import { FaMobile } from "react-icons/fa6";
import { FaLocationArrow } from "react-icons/fa";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaCalendar } from "react-icons/fa";
//Image
import SedanBgNone from "../img/sedanBgNone.png";
import SedanWithBg from "../img/sedanwithbg.png";
import EtiosBgNone from "../img/etiosbgnone.png";
import EtiosWithBg from "../img/etioswithbg.png";
import SuvBgNone from "../img/suvBgNone.png";
import SuvWithBg from "../img/suvwithbg.png";
import InnovaBgNone from "../img/InnovaBgNone.png";
import InnovaWithBg from "../img/innovawithbg.png";
//Date Picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
//Hooks 
import { useState, useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
    Google,
    GoogleMap,
    useLoadScript,
    StandaloneSearchBox,
    GoogleApiWrapper,
    DistanceMatrixService,
} from "@react-google-maps/api";
import { FaArrowLeft } from "react-icons/fa";
import { Tooltip } from 'react-tooltip';
import { sendTelegramMessageRT, sendWhatsappMessageRT, sendEmailMessageRT } from './sendMessage';
import { telegramChatId, telegramid, WhatsappCode, TelegramApi, ChatId } from '../config/config';


const OutStationRoundTrip = () => {
    const [startDate, setStartDate] = useState(null);
    const [ReturnDate, setReturnDate] = useState(null);
    const [pickUpTime, setPickUpTime] = useState('');



    //Validation
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        clearErrors,
        reset
    } = useForm();


    //Google Map options
    const searchBox = useRef(null);
    const formRef = useRef(null);
    const handlePlacesChanged = () => {
        const places = searchBox.current.getPlaces();
    }


    //Google Map calculation
    const [TotalDistance, setTotalDistance] = useState('');
    const [TotalDuration, setTotalDuration] = useState('');
    const [google, setGoogle] = useState(null);
    const [roundeDriverBeta, setRoundeDriverBeta] = useState(400);
    const [popupRate, setPopupRate] = useState(0);
    const [formData, setFormData] = useState('');


    const [distanceResult, setDistanceResult] = useState({
        org_distance: null,
        org_duration: null,
    });

    useEffect(() => {
        if (window.google) {
            setGoogle(window.google);
        }
    }, []);


    //Select Car
    const [listCars, setListCars] = useState({ car_amount: 0 });
    const [carImages, setCarImages] = useState(null);
    const [driverBeta, setDriverBeta] = useState(400);
    const [totRate, setTotalRate] = useState(0);
    const [isClicked, setIsClicked] = useState(false);
    const [formHide, setFormHide] = useState(true);


    const selectCar = (text, car_amount, carDriverBeta) => {
        if (text) {
            clearErrors('selectCarType');
        }
        setListCars({
            text,
            car_amount,
        });
        setRoundeDriverBeta(carDriverBeta);
        setCarImages(text);
        setValue('selectCarType', text);

    }

    const handlePreviousClick = () => {
        setIsClicked(false); // Hide the summary
        setFormHide(true); // Show the form again
    };

    const onSubmit = async (data) => {
        window.scrollTo(10, 10);

        setFormData({ ...data });
        setIsClicked(true);
        setFormHide(false);

        console.log(data);
        if (!google) {
            console.log("Google Maps API is not loaded yet.");
            return;
        }
        const distanceMatrixService = new google.maps.DistanceMatrixService();
        const distanceMatrixOptions = {
            origins: [data.SelectCity],
            destinations: [data.DestinationCity],
            travelMode: 'DRIVING'
        }

        await distanceMatrixService.getDistanceMatrix(distanceMatrixOptions, async (response, status) => {
            if (status === "OK" && response.destinationAddresses.length > 0 && response.rows.length > 0) {
                const element = response.rows[0].elements[0];
                if (element.distance && element.duration) {
                    var org_distance = element.distance.text;
                    var org_duration = element.duration.text;

                    // setTotalDistance(org_distance);
                    setTotalDuration(org_duration);
                    setDistanceResult(org_distance, org_duration);

                    const formatDate = (date) => {
                        const day = date.getDate();
                        console.log(date);

                        const month = date.getMonth() + 1; // Months are 0-based
                        console.log(month);

                        const year = date.getFullYear();
                        return new Date(year, month - 1, day); // Create a new Date object
                        console.log(year);

                    };

                    const startDate = formatDate(data.startDatef);
                    const returnDate = formatDate(data.ReturnDatef);

                    const diffTime = Math.abs(returnDate - startDate);
                    console.log(diffTime + " DiffTime");

                    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
                    console.log(diffDays + " diffDays");

                    const driverBetaValue = diffDays * roundeDriverBeta;
                    setRoundeDriverBeta(driverBetaValue);

                    console.log(driverBetaValue + " driverBetaValue");
                    console.log(roundeDriverBeta + " roundeDriverBeta");
                    console.log(org_distance + " org_distance");

                    const orgDistance = parseInt(org_distance) * 2; // Round trip distance
                    console.log(orgDistance + " orgDistance");
                    setTotalDistance(orgDistance)

                    const dailyRateLimit = 250 * diffDays;
                    console.log(dailyRateLimit + " dailyRateLimit");

                    const RoundWayRate = Math.max(orgDistance, dailyRateLimit) * parseInt(listCars.car_amount) + driverBetaValue;
                    console.log(RoundWayRate + " RoundWayRate");

                    setTotalRate(RoundWayRate);

                    console.log({ orgDistance, diffDays, RoundWayRate });

                    console.log(RoundWayRate + ",,,");

                    const localDateStringRT = ReturnDate.toLocaleDateString('en-GB');
                    const localDateString = startDate.toLocaleDateString('en-GB');

                    //Send Telegram Message
                    try {
                        await sendTelegramMessageRT(telegramid, telegramChatId, TelegramApi, ChatId, org_distance, org_duration, RoundWayRate, roundeDriverBeta, data, localDateStringRT, localDateString);
                        console.log("Telegram message sent successfully!");
                    } catch (error) {
                        console.log("Failed to send WhatsApp message, please try again.");
                    }

                    // Send email Message
                    try {
                        const sendEmail = sendEmailMessageRT();
                        const result = await sendEmail(org_distance, org_duration, RoundWayRate, roundeDriverBeta, data, localDateStringRT, localDateString);

                        if (result) {
                            console.log("Email Message sent successfully!");
                        } else {
                            console.log("Failed to send email message.");
                        }
                        console.log(JSON.stringify(formData) + " log Form Data");
                    } catch (error) {
                        console.log("Failed to send Email Message, please try again.");
                        console.error("Error details:", error);
                    }

                }

            } else {
                console.error("Error:", status);
            }

        });

    };

    // Confirm the booking and send messages
    const conformBooking = async (data) => {

        const localDateStringRT = ReturnDate.toLocaleDateString('en-GB');
        const localDateString = startDate.toLocaleDateString('en-GB');

        try {
            await sendWhatsappMessageRT(TotalDistance, TotalDuration, totRate, roundeDriverBeta, formData, localDateStringRT, localDateString);
            console.log("WhatsApp message sent successfully!");
        } catch (error) {
            console.log("Failed to send WhatsApp message, please try again.");
        }


    };

    return (
        <>
            <div>
                {formHide &&
                    <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
                        <div className='container formMain'>
                            <div className='row py-2 homeforminput'>
                                <div className='position-relative'>
                                    <input className="form-control" type='text' placeholder='Enter Full Name'
                                        {...register('firstName', { required: 'Name is required' })} />
                                    <FaUser className="position-absolute input-icon" />
                                    {errors.firstName && <p className='errorMes'>{errors.firstName.message}</p>}

                                </div>

                                <div className=' position-relative'>
                                    <span className='numcodedis  position-absolute'>🇮🇳 </span>
                                    <input className="form-control mbnumd" type='text' placeholder='Mobile Numbe'
                                        {...register('mobileNum', { required: 'Mobile Number field is required', })} />
                                    <FaMobile
                                        className="position-absolute input-icon" />
                                    {errors.mobileNum && <p className='errorMes'>{errors.mobileNum.message}</p>}
                                </div>

                                <div className='position-relative'>
                                    <StandaloneSearchBox onLoad={(ref) => (searchBox.current = ref)}
                                        onPlacesChanged={handlePlacesChanged}
                                    >
                                        <input className="form-control" type='text' placeholder='Enter pickup city'
                                            {...register('SelectCity', { required: 'Field is required' })} />
                                    </StandaloneSearchBox>
                                    <FaLocationArrow className="position-absolute input-icon" />
                                    {errors.SelectCity && <p className='errorMes'>{errors.SelectCity.message}</p>}

                                </div>

                                <div className='position-relative'>
                                    <StandaloneSearchBox
                                        onLoad={(ref) => (searchBox.current = ref)}
                                        onPlacesChanged={handlePlacesChanged}>

                                        <input className="form-control" type='text' placeholder='Enter destination city'
                                            {...register('DestinationCity', { required: 'Field is required' })} />
                                    </StandaloneSearchBox>
                                    <FaMapMarkerAlt className="position-absolute input-icon" />
                                    {errors.DestinationCity && <p className='errorMes'>{errors.DestinationCity.message}</p>}
                                </div>

                                <div className='d-flex flex-xl-nowrap flex-wrap datepickclsmain'>
                                    <div className=' datepickcls'>
                                        <div className=' position-relative'>
                                            <DatePicker
                                                selected={startDate}
                                                onChange={(date) => {
                                                    setStartDate(date);
                                                    setValue('startDatef', date);
                                                }}
                                                dateFormat="dd/MM/yyyy"
                                                minDate={new Date()}
                                                placeholderText="Pickup Date"
                                                inputMode='none'
                                                onFocus={(e) => e.target.blur()}
                                                className='custom-datepicker'
                                            />
                                            <input
                                                type="hidden"
                                                value={startDate ? startDate.toISOString() : ''}
                                                name="startDatef"
                                                id='startDatef'
                                                {...register('startDatef', { required: 'Date field is required' })} />
                                            <FaCalendar className="position-absolute input-icon FaCalendarcls" />
                                            {!startDate && errors.startDatef && <p className='errorMesdt'>{errors.startDatef.message}</p>}
                                        </div>
                                    </div>
                                    <div className='datepickcls'>
                                        <div className=' position-relative'>
                                            <DatePicker
                                                selected={ReturnDate}
                                                onChange={(date) => {
                                                    setReturnDate(date);
                                                    setValue('ReturnDatef', date);
                                                }}
                                                dateFormat="dd/MM/yyyy"
                                                // minDate={new Date()}
                                                minDate={startDate || new Date()} // Set min date for Return Date based on Pickup Date

                                                placeholderText="Return Date"
                                                inputMode='none'
                                                onFocus={(e) => e.target.blur()}
                                                className='custom-datepicker'
                                            />
                                            <input
                                                type="hidden"
                                                value={ReturnDate ? ReturnDate.toISOString() : ''}
                                                name="ReturnDatef"
                                                id='ReturnDatef'
                                                {...register('ReturnDatef', { required: 'Date field is required' })} />
                                            <FaCalendar className="position-absolute input-icon FaCalendarcls" />
                                            {!ReturnDate && errors.ReturnDatef && <p className='errorMesdt'>{errors.ReturnDatef.message}</p>}
                                        </div>
                                    </div>
                                    <div className=' datepickcls'>
                                        <div className='position-relative'>

                                            <select
                                                type='time' id='startTime' placeholder='Time' name='startTime' className='custom-datepicker'
                                                {...register('startTime', { required: 'Time Field is required' })}
                                            >
                                                <option value="">Pickup Time</option>

                                                <option value="12:00 AM">12:00 AM</option>

                                                <option value="12:30 AM">12:30 AM</option>

                                                <option value="01:00 AM">01:00 AM</option>

                                                <option value="01:30 AM">01:30 AM</option>

                                                <option value="02:00 AM">02:00 AM</option>

                                                <option value="02:30 AM">02:30 AM</option>

                                                <option value="03:00 AM">03:00 AM</option>

                                                <option value="03:30 AM">03:30 AM</option>

                                                <option value="04:00 AM">04:00 AM</option>

                                                <option value="04:30 AM">04:30 AM</option>

                                                <option value="05:00 AM">05:00 AM</option>

                                                <option value="05:30 AM">05:30 AM</option>

                                                <option value="06:00 AM">06:00 AM</option>

                                                <option value="06:30 AM">06:30 AM</option>

                                                <option value="07:00 AM">07:00 AM</option>

                                                <option value="07:30 AM">07:30 AM</option>

                                                <option value="08:00 AM">08:00 AM</option>

                                                <option value="08:30 AM">08:30 AM</option>

                                                <option value="09:00 AM">09:00 AM</option>

                                                <option value="09:30 AM">09:30 AM</option>

                                                <option value="10:00 AM">10:00 AM</option>

                                                <option value="10:30 AM">10:30 AM</option>

                                                <option value="11:00 AM">11:00 AM</option>

                                                <option value="11:30 AM">11:30 AM</option>

                                                <option value="12:00 PM">12:00 PM</option>

                                                <option value="12:30 PM">12:30 PM</option>

                                                <option value="01:00 PM">01:00 PM</option>

                                                <option value="01:30 PM">01:30 PM</option>

                                                <option value="02:00 PM">02:00 PM</option>

                                                <option value="02:30 PM">02:30 PM</option>

                                                <option value="03:00 PM">03:00 PM</option>

                                                <option value="03:30 PM">03:30 PM</option>

                                                <option value="04:00 PM">04:00 PM</option>

                                                <option value="04:30 PM">04:30 PM</option>

                                                <option value="05:00 PM">05:00 PM</option>

                                                <option value="05:30 PM">05:30 PM</option>

                                                <option value="06:00 PM">06:00 PM</option>

                                                <option value="06:30 PM">06:30 PM</option>

                                                <option value="07:00 PM">07:00 PM</option>

                                                <option value="07:30 PM">07:30 PM</option>

                                                <option value="08:00 PM">08:00 PM</option>

                                                <option value="08:30 PM">08:30 PM</option>

                                                <option value="09:00 PM">09:00 PM</option>

                                                <option value="09:30 PM">09:30 PM</option>

                                                <option value="10:00 PM">10:00 PM</option>

                                                <option value="10:30 PM">10:30 PM</option>

                                                <option value="11:00 PM">11:00 PM</option>

                                                <option value="11:30 PM">11:30 PM</option>
                                            </select>
                                            {errors.startTime && <p className='errorMesdt'>{errors.startTime.message}</p>}
                                        </div>
                                    </div>
                                    {/* <div className="datepickcls">
                                        <div className="position-relative">
                                            <DatePicker
                                                selected={startDate}
                                                onChange={(date) => {
                                                    setStartDate(date);
                                                    setValue("startDatef", date);
                                                }}
                                                dateFormat="dd/MM/yyyy"
                                                minDate={new Date()} // Disable past dates for Pickup Date
                                                placeholderText="Pickup Date"
                                                inputMode="none"
                                                onFocus={(e) => e.target.blur()}
                                                className="custom-datepicker"
                                            />
                                            <input
                                                type="hidden"
                                                value={startDate ? startDate.toISOString() : ""}
                                                name="startDatef"
                                                id="startDatef"
                                                {...register("startDatef", { required: "Date field is required" })}
                                            />
                                            <FaCalendar className="position-absolute input-icon FaCalendarcls" />
                                            {!startDate && errors.startDatef && (
                                                <p className="errorMesdt">{errors.startDatef.message}</p>
                                            )}
                                        </div>
                                    </div>

                                    <div className="datepickcls">
                                        <div className="position-relative">
                                            <DatePicker
                                                selected={ReturnDate}
                                                onChange={(date) => {
                                                    setReturnDate(date);
                                                    setValue("ReturnDatef", date);
                                                }}
                                                dateFormat="dd/MM/yyyy"
                                                minDate={startDate || new Date()} // Set min date for Return Date based on Pickup Date
                                                placeholderText="Return Date"
                                                inputMode="none"
                                                onFocus={(e) => e.target.blur()}
                                                className="custom-datepicker"
                                            />
                                            <input
                                                type="hidden"
                                                value={ReturnDate ? ReturnDate.toISOString() : ""}
                                                name="ReturnDatef"
                                                id="ReturnDatef"
                                                {...register("ReturnDatef", { required: "Date field is required" })}
                                            />
                                            <FaCalendar className="position-absolute input-icon FaCalendarcls" />
                                            {!ReturnDate && errors.ReturnDatef && (
                                                <p className="errorMesdt">{errors.ReturnDatef.message}</p>
                                            )}
                                        </div>
                                    </div> */}

                                </div>
                            </div>
                            <div className='container'>
                                <div className='row gap-2 selectCarMaincls'>
                                    <div className='carSelectHeading'><h4>Choose Vehicle</h4> </div>
                                    <input type='hidden' {...register('selectCarType', { required: 'Car field is required' })} />
                                    <div className={` 'col-5 col-lg-2 selectCarcls text-center' ${carImages === 'SEDAN' ? 'selectedImg' : ''}`}>
                                        <span>14₹/km</span>
                                        <img className='selectCarImg'
                                            src={SedanBgNone}
                                            onClick={() => selectCar("SEDAN", 14, 300)} />
                                        <span>SEDAN</span>
                                    </div>
                                    <div className={` 'col-5 col-lg-2 selectCarcls text-center' ${carImages === 'ETIOS' ? 'selectedImg' : ''}`}>
                                        <span className="">14₹/km</span>
                                        <img className='selectCarImg'
                                            src={EtiosBgNone}
                                            alt="Etios"
                                            onClick={() => selectCar("ETIOS", 14, 300)}
                                        />
                                        <span className="">ETIOS</span>
                                    </div>
                                    <div className={` 'col-5 col-lg-2 selectCarcls text-center' ${carImages === 'SUV' ? 'selectedImg' : ''}`}>
                                        <span>19₹/km</span>
                                        <img className='selectCarImg' src={SuvBgNone}
                                            onClick={() => selectCar("SUV", 19, 400)} />
                                        <span>SUV</span>
                                    </div>
                                    <div className={` 'col-5 col-lg-2 selectCarcls text-center' ${carImages === 'INNOVA' ? 'selectedImg' : ''}`}>
                                        <span>20₹/km</span>
                                        <img className='selectCarImg'
                                            src={InnovaBgNone}
                                            alt='INNOVA'
                                            onClick={() => selectCar("INNOVA", 20, 400)} />
                                        <span>INNOVA</span>
                                    </div>
                                    {errors.selectCarType && <p className='errorMesdt'>{errors.selectCarType.message}</p>}
                                </div>
                            </div>
                            <div className=' py-3'>
                                <div>
                                    <button className='findcabbtn' >
                                        FIND CABS
                                    </button>
                                </div>
                            </div>
                        </div >
                    </form>
                }
            </div >

            {isClicked && (
                <div className='mt-3 rounded-5'>
                    <table class="table">
                        <tbody>
                            <tr>
                                <th scope="row">Total Distance</th>
                                <td>{TotalDistance}</td>
                            </tr>
                            <tr>
                                <th scope="row">Total Duration: </th>
                                <td>{TotalDuration} </td>
                            </tr>
                            <tr>
                                <th scope="row">Total Rate: </th>
                                <td>{totRate} (Driver Beta Included)</td>
                            </tr>
                            <tr>
                                <th scope="row">Driver Beta: </th>
                                <td>{roundeDriverBeta}</td>
                            </tr>
                            <tr>
                                <th scope="row" >Extra Charges</th>
                                <td>Toll Gate, State, Hill Station - Permit Extra </td>
                            </tr>

                        </tbody>
                    </table>
                    <div className=' py-3 px-5' >
                        <div >
                            <button className='findcabbtn' id='conformBooking' onClick={conformBooking}>
                                Confirm Booking
                            </button>
                        </div>
                    </div>
                </div>
            )
            }
        </>
    )
}

export default OutStationRoundTrip